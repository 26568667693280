.app_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 15px;
  .footer_text_wrap {
    ul {
      padding: 0px;
      list-style: none;
      display: flex;
      align-items: center;
      margin-bottom: 0px;

      li {
        padding-right: 15px;

        a {
          text-decoration: none;
          font-size: 12px;
          color: var(--text-color);
        }
      }
    }
  }

  .footer_icn_wrap {
    ul {
      padding: 0px;
      list-style: none;
      display: flex;
      align-items: center;

      li {
        padding-left: 15px;

        a {
          color: #3b5998;
          font-size: 20px;
        }

        &:nth-child(2) a {
          color: #00acee !important;
        }

        &:nth-child(3) a {
          color: #0A66C2 !important;
        }

        &:nth-child(4) a {
          color: #57F287 !important;
        }
      }

    }
  }
}