.withdraw_title_wrap {
  margin-top: 30px;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 15px;

  h3 {
    margin: 0px;
    margin-bottom: 5px;
    color: var(--light-color);
    font-size: 16px;
  }

  p {
    margin: 0px;
    color: var(--text-color);
    font-size: 12px;
  }

  .withdraw_title_icn {
    margin-right: 30px;

    svg {
      color: var(--border-color);
      font-size: 25px;
    }
  }
}

.withdraw_bal_sum {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-color);
  padding: 10px;
  border-radius: 8px;
  margin-top: 20px;

  .input_icn {
    color: #444971;
    font-size: 28px;
  }

  p {
    margin-bottom: 0px;
    color: var(--text-color);
  }

  .input_title {
    color: var(--dark-color);
    background: var(--primary-color);
    border-radius: 20px;
    padding: 2px 6px 0px;
    font-size: 11px;
  }
}