:root {
  --font-family: Inter, "Open Sans", sans-serif;
  --primary-color: #222;
  --secondary-color: white;
  --button-color: #c000ff1f;
  --button-hover-color: #c000ff0d;
  --light-color: #222;
  --dark-color: #222;
  --text-color: #222;
  --card-color: #f9f9f9;
  --border-color: #f3d0f7;
}